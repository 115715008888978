<template>
  <div>
    <b-overlay :show="show" rounded="sm" no-fade>
      <b-card-code title="Data Notifikasi">
        <b-row class="mt-2 mb-2" align="end">
          <b-col md="3">
            <flat-pickr
              v-model="created_date"
              class="form-control"
              placeholder="Pilih Tanggal"
              :config="configs"
              @input="changeStatus()"
            />
          </b-col>
          <b-col md="3">
            <b-form-input
              id="name"
              v-model="email"
              placeholder="Cari Email"
              @input="changeStatus()"
            />
          </b-col>

          <b-col md="2" align="start">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
              @click="resetFilter()"
            >
              Reset
            </b-button>
          </b-col>

          <b-col md="3"></b-col>
        </b-row>

        <!-- table -->
        <vue-good-table
          mode="remote"
          :columns="columns"
          :rows="rows"
          :rtl="direction"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm,
          }"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field === 'created_date'">
              {{ props.row.created_date.substring(0, 10) }}
            </span>

            <span v-else-if="props.column.field === 'lineNumber'">
              {{ paging.page * 10 + (props.index + 1) }}.
            </span>
            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->

          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="paging.size"
                  :options="paging.perPageOptions"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap">
                  of {{ paging.total_elements }} entries
                </span>
              </div>

              <div>
                <b-pagination
                  :value="paging.page + 1"
                  :total-rows="paging.total_elements"
                  :per-page="paging.size"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-code>
    </b-overlay>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import axios from "@axios";
import vSelect from "vue-select";
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BPagination,
  BFormFile,
  BOverlay,
  BFormSelect,
  BBadge,
  BFormTextarea,
  BMedia,
  BAvatar,
  BMediaBody,
  BMediaAside,
  BAvatarGroup,
  BImg,
  BInputGroupPrepend,
  BCard,
  BCardText,
  BCardBody,
  BDropdownItem,
  BDropdown,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    BCardCode,
    VBTooltip,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BPagination,
    VueGoodTable,
    BFormFile,
    BOverlay,
    vSelect,
    BFormSelect,
    BBadge,
    BFormTextarea,
    BMedia,
    BAvatar,
    BMediaBody,
    BMediaAside,
    BAvatarGroup,
    BImg,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BCardText,
    BDropdownItem,
    BDropdown,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      //here
      file: null,

      combo_profesi: [],
      combo_syarat: [],

      log: [],
      dir: false,
      pages: ["5", "10", "20", "30"],

      paging: {
        page: 0,
        total_pages: 0,
        size: 10,
        total_elements: 0,
        perPageOptions: [10, 25, 50, 100],
      },

      columns: [
        {
          label: "No",
          field: "lineNumber",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Tanggal",
          field: "created_date",
          thClass: "text-center",
        },
        {
          label: "Email",
          field: "email",
          thClass: "text-center",
        },
        {
          label: "Pesan",
          field: "pesan",
          thClass: "text-center",
        },
      ],
      rows: [],
      searchTerm: "",

      name: "",
      sort: "",

      create_time: "",

      show: false,
      aksi: "",
      id_sip: "",

      created_date: "",
      email: "",
      id_pendaftar: localStorage.getItem("id_pendaftar"),

      configs: {
        allowInput: {
          allowInput: true,
          dateFormat: "d-m-Y",
        },
      },
    };
  },

  beforeMount() {
    this.getData(this.paging.size, this.paging.page);
  },

  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true;
        return this.dir;
      }
      this.dir = false;
      return this.dir;
    },
  },

  created() {
    //here
  },

  methods: {
    getData(size, page) {
      this.show = true;

      let search1 = "";
      if (this.created_date) {
        search1 = "created_date=" + this.created_date + "&";
      } else {
        search1;
      }

      let search2 = "";
      if (this.email) {
        search2 = "email=" + this.email + "&";
      } else {
        search2;
      }

      let search3 = "";
      if (this.id_pendaftar) {
        search3 = "id_pendaftar=" + this.id_pendaftar + "&";
      } else {
        search3;
      }

      return new Promise(() => {
        axios
          .get(
            "/notification/list_notif?" +
              search1 +
              search2 +
              search3 +
              "page=" +
              page +
              "&size=" +
              size
          )
          .then((res) => {
            this.rows = res.data.content.data;

            this.paging.page = res.data.content.page;
            this.paging.size = res.data.content.size;
            this.paging.total_elements = res.data.content.total_row_filtered;
            this.paging.total_pages = res.data.content.total_page;

            this.show = false;
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
              this.$router.push("/login");
            } else {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: error.response.data.detail,
                  icon: "XCircleIcon",
                  variant: "danger",
                },
              });
              this.show = false;
            }
            this.show = false;
          });
      });
    },

    showModalEdit(props) {
      this.$router.push("/dashboard/" + props.row.id + "/ubah");
    },

    showModalBerkas(props) {
      this.$router.push(
        "/dashboard/" + props.row.id_pendaftar + "/" + props.row.id + "/detail"
      );
    },

    showModalBerkasNull() {
      this.$toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "File Dokumen Belum Ada Yang Terupload",
          icon: "XCircleIcon",
          variant: "danger",
        },
      });
    },

    hideAllBerkas() {
      this.allNamaBerkas = [];
      this.id_sip_berkas = "";
      this.nama_sip_berkas = "";

      this.getBerkas = {};
      this.openBerkasTrue = false;

      this.$refs["ref-get-all-berkas"].hide();
    },

    openBerkas(item) {
      return new Promise(() => {
        axios
          .get(
            "admin/get_berkas_sip?nama_berkas=" +
              item +
              "&id_sip=" +
              this.id_sip_berkas
          )
          .then((res) => {
            this.getBerkas = res.data[0];
            this.getBerkas.created_date = res.data[0].created_date.substr(
              0,
              10
            );
            this.getBerkas.nama_sip = this.nama_sip_berkas;

            this.openBerkasTrue = true;
          });
      });
    },

    hideGetBerkas() {
      this.$refs["ref-get-berkas"].hide();
    },

    pencabutanSIP(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text:
          "Untuk Melakukan Pencabutan SIP atas nama " + props.row.nama + " ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Cabut SIP",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.pencabutanSIPApi(props);
        }
      });
    },

    pencabutanSIPApi(props) {
      return new Promise(() => {
        axios
          .put("admin/cabut_sip?id_sip=" + props.row.id, this.formCabutSIP)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Pencabutan SIP Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Pencabutan SIP Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          });
      });
    },

    deleteInfo(props) {
      this.$swal({
        title: "Apakah Anda Yakin ?",
        text: "Data yang terhapus tidak dapat dikembalikan",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Hapus!",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteApi(props);
        }
      });
    },

    deleteApi(props) {
      return new Promise(() => {
        axios
          .delete("pendaftar/delete_sip?id_sip=" + props.row.id)
          .then((res) => {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Hapus Data Berhasil`,
                icon: "CheckCircleIcon",
                variant: "success",
                text: `Hapus Data SIP Berhasil`,
              },
            });
            this.getData(this.paging.size, this.paging.page);
          });
      });
    },

    updateStatusSIP() {
      if (this.formUpdateSIP.status_sip === 1) {
        this.statusTerbit = true;
        this.statusTolak = false;
      } else if (this.formUpdateSIP.status_sip === 9) {
        this.statusTolak = true;
        this.statusTerbit = false;
      }
    },

    resetFilter() {
      this.created_date = "";
      this.email = "";
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    downloadPDF(props) {
      window.open(
        "https://sipolnakes.salatiga.go.id/api/v1/pendaftar/download_sip?id_sip=" +
          props.row.id
      );
    },

    changeStatus() {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },

    handleSearch(searching) {
      const page = (this.paging.page = 0);
      this.getData(this.paging.size, page);
    },
    handleChangePage(value) {
      const page = value - 1;
      this.getData(this.paging.size, page);
    },
    handlePageChange(active) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
    onSortChange(params) {
      this.sort = `${params[0].field}`;
      this.getData(this.paging.size, this.paging.page);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.modal-header .close {
  display: none;
}
</style>
